exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-members-js": () => import("./../../../src/pages/members.js" /* webpackChunkName: "component---src-pages-members-js" */),
  "component---src-templates-child-js": () => import("./../../../src/templates/child.js" /* webpackChunkName: "component---src-templates-child-js" */),
  "component---src-templates-downloads-js": () => import("./../../../src/templates/downloads.js" /* webpackChunkName: "component---src-templates-downloads-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-housing-application-js": () => import("./../../../src/templates/housingApplication.js" /* webpackChunkName: "component---src-templates-housing-application-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-parent-js": () => import("./../../../src/templates/parent.js" /* webpackChunkName: "component---src-templates-parent-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-properties-js": () => import("./../../../src/templates/properties.js" /* webpackChunkName: "component---src-templates-properties-js" */),
  "component---src-templates-staff-js": () => import("./../../../src/templates/staff.js" /* webpackChunkName: "component---src-templates-staff-js" */)
}

